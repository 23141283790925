<template>
  <div class="fission-container">
    <!-- navbar -->
    <QbNavbar title="裂变式盈利"></QbNavbar>
    <!-- title -->
    <qb-title
      title="裂变式盈利"
      subtitle="Compulsory for entrepreneurs"
    ></qb-title>
    <!-- video -->
    <FissionVideo></FissionVideo>
    <!-- lecturer -->
    <FissionLecturer></FissionLecturer>
    <!-- reserve -->
    <FissionReserve></FissionReserve>
    <!-- fission-content -->
    <FissionCourse></FissionCourse>
    <!-- fission-schedule -->
    <FissionSchedule></FissionSchedule>
    <!-- qbfamily-case -->
    <QbfamilyCase></QbfamilyCase>
    <!-- fission-content -->
    <FissionContent></FissionContent>
    <!-- fission-question -->
    <FissionQuestion></FissionQuestion>
    <!-- application -->
    <qb-application id="fission-application"></qb-application>
  </div>
</template>

<script>
import FissionVideo from './components/fission-video.vue'
import FissionLecturer from './components/fission-lecturer.vue'
import FissionReserve from './components/fission-reserve.vue'
import FissionCourse from './components/fission-course.vue'
import FissionSchedule from './components/fission-schedule.vue'
import QbfamilyCase from './components/qbfamily-case.vue'
import FissionContent from './components/fission-content.vue'
import FissionQuestion from './components/fission-question.vue'
export default {
  name: 'Fission',
  components: {
    FissionVideo,
    FissionLecturer,
    FissionReserve,
    FissionCourse,
    FissionSchedule,
    QbfamilyCase,
    FissionContent,
    FissionQuestion
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped></style>
