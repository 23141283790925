<template>
  <div class="fissioncontent-container">
    <h3 class="title">课程内容</h3>
    <ul>
      <li>如何捆绑人才，把员工变成股东</li>
      <li>如何捆绑客户，让客户疯狂转介绍</li>
      <li>如何拓客、留客、锁客、消费升级</li>
      <li>如何不花自己钱，让自己的企业裂变全国</li>
      <li>如何让消费者不用花钱、代理商不用卖货、投资人只赚不赔</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'FissionContent'
}
</script>

<style lang="less" scoped>
.fissioncontent-container {
  .title {
    font-size: 24px;
    font-weight: 700;
    color: #010343;
    line-height: 33px;
    text-align: center;
    margin-top: 11px;
    margin-bottom: 20px;
  }
  li {
    width: 335px;
    font-size: 18px;
    font-weight: 700;
    color: #010343;
    line-height: 25px;
    text-align: center;
    background: #f7f9fd;
    margin: 0 auto;
    margin-bottom: 16px;
    padding: 15px 22px 15px 18px;
  }
}
</style>
