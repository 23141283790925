<template>
  <div class="qbfamilyCase-container">
    <h3 class="title">泉邦家族案例展示</h3>
    <van-swipe :autoplay="300000" class="swiper">
      <van-swipe-item v-for="(image, index) in images" :key="index">
        <van-image width="100%" height="100%" lazy-load :src="image" />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: 'QbfamilyCase',
  data() {
    return {
      images: [
        require('@/assets/images/fission/family-case.png'),
        require('@/assets/images/fission/family-case.png'),
        require('@/assets/images/fission/family-case.png'),
        require('@/assets/images/fission/family-case.png'),
        require('@/assets/images/fission/family-case.png')
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.qbfamilyCase-container {
  padding-top: 48px;
  .title {
    font-size: 21px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 45px;
    text-align: center;
  }
  ::v-deep .van-swipe__indicators {
    .van-swipe__indicator {
      position: relative;
      width: 6px;
      height: 6px;
      border: 1px solid #010343;
      background-color: #fff;
      opacity: 1;
    }
    i.van-swipe__indicator--active {
      width: 12px;
      height: 12px;
      background: #000;
      border: 1px solid #010343;
      transform: translateY(-20%);
    }
  }
}
</style>
