<template>
  <div class="fissionschedule-container">
    <h3 class="title">裂变式盈利课程表</h3>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide class="swiper-item" v-for="item in 3" :key="item">
        <img src="@/assets/images/fission/fission-schedule1.png" alt="" />
      </swiper-slide>
    </swiper>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: 'FissionSchedule',
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 20,
        loop: true,
        centeredSlides: true,
        autoplay: {
          delay: 2000
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets'
        }
      }
    }
  },
  components: {
    swiper,
    swiperSlide
  }
}
</script>

<style lang="less" scoped>
.fissionschedule-container {
  margin-bottom: 35px;
  .title {
    font-size: 21px;
    font-weight: 700;
    line-height: 29px;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 30px;
  }
  .swiper-item {
    width: 251px;
    height: 317px;
  }
  .swiper-pagination {
    left: 50%;
    transform: translateX(-50%);
    margin-top: 6px;
    ::v-deep .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      border: 1px solid #010343;
      background-color: #fff;
      opacity: 1;
      margin-right: 6px;
      transition: all 0.3s;
    }
    ::v-deep .swiper-pagination-bullet-active {
      width: 12px;
      height: 12px;
      background: #000;
      border: 1px solid #010343;
      transform: translateY(20%);
    }
  }
}
</style>
