<template>
  <div class="lecturer-container">
    <h3 class="title">裂变式盈利讲师</h3>

    <!-- <swiper class="swiper" :options="swiperOption">
      <swiper-slide class="swiper-item" v-for="item in 10" :key="item">
        {{ item }}
      </swiper-slide>
    </swiper> -->
    <div class="swiper-item">
      <van-image
        :src="require('@/assets/images/lecturer/mentor_2.png')"
        lazy-load
      ></van-image>
    </div>

    <QbMore top="45" @click.native="$router.push('/lecturer')"></QbMore>
  </div>
</template>

<script>
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'
import QbMore from '@/components/qb-more.vue'
export default {
  name: 'Lecturer',
  components: {
    //   swiper,
    //   swiperSlide,
    QbMore
  },
  data() {
    return {
      // swiperOption: {
      //   slidesPerView: 'auto',
      //   spaceBetween: 20,
      //   loop: true,
      //   centeredSlides: true,
      //   autoplay: {
      //     delay: 2000
      //   }
      // }
    }
  }
}
</script>

<style lang="less" scoped>
.lecturer-container {
  padding-top: 62px;
  padding-bottom: 31px;
  background: #f7f9fd;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
  .title {
    font-size: 21px;
    font-weight: 600;
    line-height: 29px;
    text-align: center;
    margin-bottom: 39px;
  }

  .swiper-item {
    width: 260px;
    height: 412px;
    background: #00123f;
    margin: 0 auto;
  }
}
</style>
