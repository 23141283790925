<template>
  <div class="fission-question-container">
    <van-image
      width="100%"
      height="100%"
      lazy-load
      :src="require('@/assets/images/fission/fission-question.png')"
    />
  </div>
</template>

<script>
export default {
  name: ''
}
</script>

<style lang="less" scoped>
.fission-question-container {
  width: 375px;
  height: 311px;
  margin-top: 15px;
}
</style>
