<template>
  <div class="fission-content-container">
    <h3 class="title">泉邦裂变式盈利课程</h3>
    <h3 class="subtitle">
      现场分析行业巨头的商业模式，1-3个落地方案带回家实施落地，加入企业家的行业圈子，泉邦带您一起裂变全国。
    </h3>
    <!-- <van-swipe :autoplay="300000" class="swiper"> -->
    <!-- <van-swipe-item v-for="(image, index) in images" :key="index"> -->
    <van-image
      width="100%"
      height="100%"
      lazy-load
      :src="require('@/assets/images/home/course-fission.png')"
    />
    <!-- </van-swipe-item> -->
    <!-- </van-swipe> -->
  </div>
</template>

<script>
export default {
  name: 'FissionContent',
  components: {},
  data() {
    return {
      // images: [
      //   require('@/assets/images/home/course-fission.png'),
      //   require('@/assets/images/home/course-fission.png'),
      //   require('@/assets/images/home/course-fission.png'),
      //   require('@/assets/images/home/course-fission.png'),
      //   require('@/assets/images/home/course-fission.png'),
      //   require('@/assets/images/home/course-fission.png')
      // ]
    }
  }
}
</script>

<style lang="less" scoped>
.fission-content-container {
  padding-top: 29px;
  text-align: center;
  .title {
    font-size: 21px;
    font-weight: 600;
    line-height: 29px;
    margin-bottom: 23px;
  }
  .subtitle {
    padding: 0 25px;
    font-size: 10px;
    font-weight: 400;
    color: #7f7f7f;
    line-height: 20px;
    margin-top: 12px;
  }
  ::v-deep .van-swipe__indicators {
    .van-swipe__indicator {
      position: relative;
      width: 6px;
      height: 6px;
      border: 1px solid #010343;
      background-color: #fff;
      opacity: 1;
    }
    i.van-swipe__indicator--active {
      width: 12px;
      height: 12px;
      background: #000;
      border: 1px solid #010343;
      transform: translateY(-20%);
    }
  }
}
</style>
