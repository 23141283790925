<template>
  <div class="reserve-container">
    <div class="logo"></div>
    <div class="title">您是否有这些问题？</div>
    <div class="introdution">
      答案并不复杂，无非就是看商业模式是否成立，能否给企业带来持续不断的收入即可。纵观脱颖而出的企业与泯然于众的企业之间的区别，我们不难看出，大部分企业其实并不知道什么样的盈利模式才真正适合自己。
    </div>
    <div class="btn" @click="handleGoApplication"></div>
  </div>
</template>

<script>
export default {
  name: 'FissionReserve',
  methods: {
    handleGoApplication() {
      document.querySelector('#fission-application').scrollIntoView()
    }
  }
}
</script>

<style lang="less" scoped>
.reserve-container {
  width: 375px;
  height: 389px;
  background: #f7f9fd;
  padding: 80px 50px 0 48px;
  .logo {
    width: 26px;
    height: 28px;
    background: url(~@/assets/images/fission/reserve-logo.png);
    background-size: cover;
    margin-bottom: 10px;
  }
  .title {
    font-size: 23px;
    font-weight: 500;
    color: #010343;
    line-height: 32px;
    margin-bottom: 33px;
  }
  .introdution {
    width: 277px;
    height: 81px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #010343;
    line-height: 20px;
    margin-bottom: 25px;
  }
  .btn {
    width: 104px;
    height: 33px;
    background: url(~@/assets/images/fission/reserve-btn.png);
    background-size: cover;
  }
}
</style>
